import React from 'react';
import logoIcade from './icade.png';
import logoAllocab from './allocab.png';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import './App.css';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function App() {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  //extracts error message from URL (if existing)
  let error = params.get('error');
  let errorText = null;
  let contactAllocab = null;

  // manages error messages
  if (error) {
    console.log('error ' + error + ' fetch');

    if (error === '0') {
      errorText = 'Une erreur technique est survenue. Merci d\'essayer à nouveau de vous connecter. Si ça ne fonctionne pas, contactez le support technique Allocab. ';
    } else if (error === '1') {
      errorText = 'La demande de connexion est invalide. Vos nom, prénom, email et numéro de téléphone doivent être renseignés sur votre compte Icade. Si c\'est déjà le cas, merci de contacter le support technique Allocab.';
    } else if (error === '2') {
      errorText = 'Ce mode de connexion est réservé aux salariés Icade.';
    } else if (error === '3') {
      errorText = 'Vous n\'êtes pas autorisé à accéder à ce mode de connexion.';
    } else {
      errorText = 'Une erreur technique imprévue est survenue. Merci de contacter le support Allocab.';
    }
    contactAllocab = <a href="https://www.allocab.com/informations/contact/">Contacter le support technique</a>;
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <img src={logoIcade} alt="logoIcade" className="Logo-left"/>
        </Grid>
        <Grid item xs={6}>
          <img src={logoAllocab} alt="logoAllocab" className="Logo-right"/>
        </Grid>
      </Grid>
      <hr className="Le-hr"/>
      <div className="Text">
        <h4>Bienvenue sur la plateforme de connexion à Allocab dediée aux salariés Icade.</h4>
      </div>
      <div className="Button-cnx">
        <Button variant="contained" href="/auth/sso/login" className={classes.button}>
          Connexion à Allocab
        </Button>
      </div>
      <div className="Text-error">
        <b>{errorText}</b>&nbsp;{contactAllocab}
      </div>
      <div>

      </div>
      <div className="Bottom-text">
        <h5>Vous n'êtes pas salarié Icade ? <a href="https://www.allocab.com/webapp/#/auth/">Cliquez ici</a></h5>
      </div>
    </div>
  );

}

export default App;
